import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = (props) => {
  const {
    className, children, hideLabel, icon, type, text, title: titleProp, ...rest
  } = props;
  const title = titleProp || (hideLabel && children) || null;

  return (
    // eslint-disable-next-line react/button-has-type
    <button {...rest} className={classNames('btn', className)} title={title} type={type}>
      <span className={hideLabel ? 'sr-only' : null}>
        {text || children}
      </span>
      {icon && <i className={`fas ${icon}`} />}
    </button>
  );
};

/* eslint-disable react/require-default-props */
Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  hideLabel: PropTypes.bool,
  icon: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};
/* eslint-enable react/require-default-props */

Button.defaultProps = {
  type: 'button',
};

export default Button;
